import * as React from "react"
import { Link, graphql } from "gatsby"
import { motion } from 'framer-motion'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Cta from "../components/cta"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo 
        title="Startup blog" 
        description="The Launch Lab blog is a must-read for early stage startup founders. It contains practical advice about starting a startup, developing a startup, and growing a startup."
        pathname="/blog/"
    />

        <section role="main" className="bg-purple white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      >Startup Blog</h1>
                      <h2 className="hero-lead"
                      >
                          Our posts are written to help Australian startup founders get started
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>
        
        <section className="section-pad-sm" id="anchor">
            <div className="container">
                <div className="row">
                    <motion.div 
                      className="posts-wrapper"
                      initial="hidden"
                      animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0] }} 
                      transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                    >
                        <div>
                            {posts.map(post => {
                            const title = post.frontmatter.title || post.fields.slug
                            const imageData = post.frontmatter.featuredImage ? getImage(post.frontmatter.featuredImage.childImageSharp.gatsbyImageData) : undefined;
                            return (
                                <div key={post.fields.slug}>
                                    <article
                                        className="a-post"
                                        itemScope
                                        itemType="http://schema.org/Article"
                                    >
                                        <div className="blog-index-img">
                                            {imageData && <GatsbyImage image={imageData} alt={post.frontmatter.alt} />}
                                        </div>
                                        
                                        <div className="blog-index-text">
                                            <h3>
                                                <Link to={post.fields.slug} itemProp="url">
                                                    <span itemProp="headline">{title}</span>
                                                </Link>
                                            </h3>
                                            <small>By <Link to="/author/">{post.frontmatter.author}</Link> on {post.frontmatter.date}</small>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                __html: post.frontmatter.description || post.excerpt,
                                                }}
                                                itemProp="description"
                                            />
                                            <Link to={post.fields.slug} itemProp="url" className="btn-link">Read more <ArrowRight /></Link>
                                        </div>
                                    </article>
                                </div>
                            )
                            })}
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <Cta
        text="Contact Launch Lab for web design, web development and startup quotes from an experienced Australian team"  
        />

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          title
          description
          alt
          author
          featuredImage {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 1.8
                    layout: FULL_WIDTH
                    quality: 100
                    transformOptions: {cropFocus: CENTER}
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
          }
        }
      }
    }
  }
`